<template>
  <div :class="wrapperClass">
    <h1 class="text-size-h1 mb-m text-bold">
      {{ $metaInfo.title }}
    </h1>
    <div class="row">
      <div class="col-md-8 col-xs-12 mb-m">
        <div v-if="allowCreateTicketForm">
          <p class="mb-m">
            Если у&nbsp;вас возникли проблемы или вопросы при работе
            с&nbsp;личным кабинетом, выберите тему обращения, и коротко опишите
            проблему. Мы&nbsp;постараемся помочь вам как можно быстрее.
          </p>

          <ValidationObserver
            ref="form"
            tag="form"
            @submit.prevent="handleSubmit">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              mode="aggressive">
              <BaseSelect
                v-model="subject"
                :options="subjects"
                label="title"
                :allow-empty="false"
                placeholder="Тема обращения"
                :errors="errors"
                class="mb-s"></BaseSelect>
            </ValidationProvider>
            <label
              for="subject"
              class="form-label">
              Ваше сообщение
            </label>
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ min: 10, max: 420, required: true }"
              mode="aggressive">
              <BaseInput
                v-model="message"
                name="message"
                placeholder="Опишите проблему"
                type="textarea"
                :errors="errors"
                rows="6"></BaseInput>
            </ValidationProvider>

            <BaseButton
              class="mt-m"
              type="submit"
              :disabled="!!timeToNextRequest"
              >Отправить запрос</BaseButton
            >

            <div
              v-if="timeToNextRequest"
              class="mt-m">
              Отправить другой запрос можно через {{ timeToNextRequest }} сек.
            </div>
          </ValidationObserver>

          <div class="mb-l mt-l">
            <p>
              Если у вас нет технической проблемы, но есть вопрос, загляните в
              раздел
              <a
                class="link"
                href="https://ntcontest.ru/participants/faq/"
                target="_blank"
                rel="noopener noreferrer"
                >FAQ на сайте НТО</a
              >. Если там нет ответа, напишите нам в сообщения
              <a
                class="link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://vk.com/nticontest"
                >группы НТО в VK</a
              >
              или на почту
              <a
                :href="mailToLink"
                class="link"
                >{{ $store.state.supportEmail }}</a
              >.
            </p>
          </div>
        </div>
        <div
          v-else-if="status === 'success'"
          class="typography">
          <p>
            Ваш запрос успешно отправлен. Специалист службы поддержки свяжется с
            вами <strong>{{ responseTime }}</strong
            >. Ответ будет отправлен на адрес электронной почты, указанный в
            вашем профиле <strong>{{ user.email }}</strong
            >.
          </p>
          <p>Режим работы службы поддержки {{ supportWorkTime }}</p>
        </div>
        <div v-else-if="status === 'error'">
          <p>
            Произошла ошибка при отправке запроса. Попробуйте еще раз или
            <a
              href="#"
              class="link link--pseudo text-bold copy-link"
              :class="{ 'is-active': isCopyed }"
              @click.prevent="handleCopy"
              ><span>скопируйте отчет</span
              ><span><BaseIcon glyph="done" />&nbsp;готово</span></a
            >
            и напишите нам в&nbsp;сообщения
            <a
              class="link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://vk.com/nticontest"
              >группы НТО в VK</a
            >
            или на почту
            <a
              :href="mailToLink"
              class="link"
              >{{ $store.state.supportEmail }}</a
            >.
          </p>
        </div>
        <div
          v-else
          class="typography">
          <p>
            Если у&nbsp;вас возникли проблемы при работе с&nbsp;личным
            кабинетом,
            <a
              href="#"
              class="link link--pseudo text-bold copy-link"
              :class="{ 'is-active': isCopyed }"
              @click.prevent="handleCopy"
              ><span>скопируйте отчет</span
              ><span><BaseIcon glyph="done" />&nbsp;готово</span></a
            >
            и&nbsp;пришлите его в&nbsp;сообщения группы
            <a
              href="https://vk.com/nticontest"
              target="_blank"
              class="link text-bold"
              rel="noopener noreferrer"
              >НТО в&nbsp;VK</a
            >
            или на&nbsp;почту
            <a
              class="link link--pseudo text-bold"
              :href="mailToLink"
              >{{ $store.state.supportEmail }}</a
            >.
          </p>

          <p>
            У вас нет технической проблемы, но есть вопрос? Загляните в раздел
            FAQ на
            <a
              class="link"
              href="https://ntcontest.ru/participants/faq/"
              target="_blank"
              rel="noopener noreferrer"
              >сайте НТО</a
            >. Если там нет ответа, напишите нам в сообщения
            <a
              class="link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://vk.com/nticontest"
              >группы НТО в VK</a
            >
            или на почту
            <a
              :href="mailToLink"
              class="link"
              >{{ $store.state.supportEmail }}</a
            >.
          </p>
        </div>
      </div>
      <div
        v-if="user"
        class="col-md-4 col-xs-12">
        <div class="mb-m">
          <div>
            <a
              href="https://ntcontest.ru/participants/faq/"
              class="link"
              target="_blank"
              >Вопрос-Ответ</a
            >
          </div>
          <div>
            <a
              href="https://vk.com/nticontest"
              class="link"
              target="_blank"
              >Группа НТО Вконтакте</a
            >
          </div>
          <div>
            Служба поддержки:

            <a
              :href="mailToLink"
              class="link"
              target="_blank"
              >{{ $store.state.supportEmail }}</a
            >
          </div>
        </div>
        <BaseButton
          class="mb-l"
          theme="secondary"
          @click="handleCopy"
          ><DoneText
            text="Готово"
            :done="isCopyed"
            >Скопировать отчет</DoneText
          ></BaseButton
        >
      </div>
    </div>

    <BaseButton
      v-if="!user || !user.id"
      tag="a"
      :href="`${$store.state.clientApiPrefix}/auth/go`"
      >Перейти в личный кабинет</BaseButton
    >
  </div>
</template>
<script>
import { middleware } from "./middleware";
import { mapGetters } from "vuex";
import { PARTICIPANT_ROLE } from "@/constants";
import { copyToClipboard, delay } from "@/utils";
import { getReport } from "@/utils/help";
import { subjects } from "@/data/help.js";
import dayjs from "@/plugins/dayjs";
import { talentRequest } from "@/services/api";
import DoneText from "@/components/DoneText.vue";

const SUPPORT_UTC_START = 5; // 8 утра
const SUPPORT_UTC_END = 16; // 19-вечера
const SUPPORT_REQUEST_MIN = 1; // 1 час
const SUPPORT_REQUEST_MAX = 2; // 2 часа
const SUPPORT_DELAY_PER_REQUEST = 5 * 60 * 1000; // 5 минут
const LS_SUPPORT_REQUEST_KEY = "_lsrts_";

export default {
  name: "HelpPage",
  components: {
    DoneText,
  },
  beforeRouteEnter: middleware,
  data() {
    return {
      isCopyed: false,
      subject: null,
      loading: false,
      message: "",
      status: "",
      lastRequestTimestamp: 0,
      ts: Date.now(),
      req_timer: null,
    };
  },
  metaInfo() {
    return {
      title: `Служба поддержки`,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      age: "user/age",
      selectedList: "participant/selectedList",
      teamStageList: "participant/teamStageList",
      isMentor: "user/isMentor",
      isParticipant: "user/isParticipant",
    }),
    allowCreateTicketForm() {
      return this.user && !this.status;
    },
    wrapperClass() {
      const layout = this.$store.state.ui.layout;
      const result = ["help-page", `help-page--variant-${layout}`];
      if (layout === "reg") {
        result.push("container");
      }
      return result;
    },
    profiles() {
      return this.$store.state.profile.profiles;
    },
    report() {
      const isParticipant = this.user?.role === PARTICIPANT_ROLE;

      const report = getReport({
        release: this.$store.state.release,
        user: this.user,
        ...(isParticipant && {
          selectedProfiles: this.selectedList,
          teamProfiles: this.teamStageList,
          profiles: this.profiles,
          tracks: this.$store.state.tracks,
          track: this.$store.state.participant.track,
          teamProfileTracks:
            this.$store.getters["participant/teamProfileTracks"],
          participantId: this.user?.participant?.id,
          season: this.user?.participant?.season_id,
        }),
        ...(this.$route.query.err && {
          Ошибка: this.$route.query.err,
        }),
      });

      return Object.entries(report)
        .map(([k, v]) => {
          return `${k}: ${v}`;
        })
        .join("\n");
    },
    mailToLink() {
      const subject = "Обращение в службу поддержки НТО";
      return `mailto:${
        this.$store.state.supportEmail
      }?subject=${encodeURIComponent(subject)}`;
    },
    subjects() {
      const tags = ["all"];

      if (this.isMentor) {
        tags.push("mentor");
      }

      if (this.isParticipant) {
        tags.push("participant");
        const trackAlias = this.$store.state.participant.track?.alias;
        if (trackAlias) {
          tags.push(trackAlias);
        }
      }
      return subjects.filter((n) => {
        return n.for.some((t) => tags.includes(t));
      });
    },
    responseTime() {
      const now = dayjs().utc().get("hours");
      if (now >= SUPPORT_UTC_START && now <= SUPPORT_UTC_END) {
        return `в течение ${SUPPORT_REQUEST_MIN} - ${SUPPORT_REQUEST_MAX} часов`;
      }

      if (now < SUPPORT_UTC_START) {
        const diff = SUPPORT_UTC_START - now;
        return `через ${diff + SUPPORT_REQUEST_MIN} - ${
          diff + SUPPORT_REQUEST_MAX
        } часов`;
      }

      const diff = 24 - now + SUPPORT_UTC_START;
      return `через ${diff + SUPPORT_REQUEST_MIN} - ${
        diff + SUPPORT_REQUEST_MAX
      } часов`;
    },
    supportWorkTime() {
      return `c 0${SUPPORT_UTC_START + 3}:00 до ${
        SUPPORT_UTC_END + 3
      }:00 (по МСК)`;
    },

    timeToNextRequest() {
      const { lastRequestTimestamp, ts } = this;
      if (!lastRequestTimestamp) {
        return;
      }
      const diff = ts - lastRequestTimestamp;
      if (diff > SUPPORT_DELAY_PER_REQUEST) {
        return;
      }

      return dayjs
        .duration(SUPPORT_DELAY_PER_REQUEST - diff, "milliseconds")
        .format("HH:mm:ss");
    },
  },

  created() {
    const t = localStorage?.getItem(LS_SUPPORT_REQUEST_KEY);
    if (t) {
      const time = +t;
      const now = this.ts;
      if (now - time < SUPPORT_DELAY_PER_REQUEST) {
        this.lastRequestTimestamp = time;
        this.req_timer = setInterval(() => {
          this.ts = Date.now();
          if (!this.timeToNextRequest) {
            clearInterval(this.req_timer);
          }
        }, 1000);
      } else {
        localStorage?.removeItem(LS_SUPPORT_REQUEST_KEY);
      }
    }
  },

  beforeDestroy() {
    clearInterval(this.req_timer);
  },

  methods: {
    async handleCopy() {
      this.isCopyed = true;
      copyToClipboard(this.report);
      await delay(1500);
      this.isCopyed = false;
    },
    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      this.loading = true;
      const title = this.subject?.title || "Другое";
      const payload = {
        subject: `НТО - ${title}`,
        message: `\r\n ==== ОТЧЕТ ==== \r\n ${this.report}\r\n`,
        channel_id: process.env.VUE_APP_USDESK_CHANLE_ID,
        client_email: this.user.email,
      };

      if (this.message) {
        payload.message = `${this.message}\r\n\n${payload.message}`;
      }

      if (process.env.VUE_APP_ENV !== "production") {
        payload.message = `!Этот запрос отправлен со Staging. На него реагировать не нужно!\n\n ${payload.message}`;
      }
      try {
        await talentRequest({
          method: "POST",
          url: "/self/usedesk/create-ticket",
          data: payload,
        });
        const now = Date.now();
        this.status = "success";
        this.lastRequestTimestamp = now;
        localStorage.setItem(LS_SUPPORT_REQUEST_KEY, now);
        this.req_timer = setInterval(() => {
          this.ts = Date.now();
          if (!this.timeToNextRequest) {
            clearInterval(this.req_timer);
          }
        }, 1000);
      } catch (error) {
        this.status = "error";
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.copy-link {
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  position: relative;

  span {
    transition: opacity 0.2s;
  }

  & > span:nth-child(1) {
    opacity: 1;
  }
  & > span:nth-child(2) {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
  }
  &.is-active > span:nth-child(1) {
    opacity: 0;
  }
  &.is-active > span:nth-child(2) {
    opacity: 1;
  }

  &.is-active {
    // color: @success-color;
    border-bottom-color: transparent !important;
  }
}
</style>
